import CountDown from "../components/CountDown";
import { useState } from "react";
import Gift from "../components/Gift/Gift";

const Home = () => {
    const [isFinish, setIsFinish] = useState(false);
    return (
        <>
            {isFinish ? <Gift/> : <CountDown setIsFinish={setIsFinish}/>}
        
        
        </>);
}
 
export default Home;