import { useState } from "react";

const CountDown = ({setIsFinish}) => {
  const [days, setDays] = useState();
  const [hours, setHours] = useState();
  const [mins, setMins] = useState();
  const [seconds, setSeconds] = useState();

  const formatTime = (time) => {
    return time < 10 ? `0${time}` : time;
  };

  setInterval(() => {
    const futureDate = "6 july 2021";
    const futureYearsDate = new Date(futureDate);
    const currentDate = new Date();

    const totalSeconds = (futureYearsDate - currentDate) / 1000;
    if (totalSeconds <= 0) {
      setIsFinish(true);
    }
    setDays(formatTime(Math.floor(totalSeconds / 3600 / 24)));
    setHours(formatTime(Math.floor(totalSeconds / 3600) % 24));
    setMins(formatTime(Math.floor(totalSeconds / 60) % 60));
    setSeconds(formatTime(Math.floor(totalSeconds) % 60));
  }, 1000);

  return (
    <div className="bg-body h-screen bg-cover bg-center bg-no-repeat flex justify-center items-center">
      <div className="backdrop-filter backdrop-blur-sm text-xl  md:text-7xl text-gray-200 p-10 rounded-3xl font-serif ">
        <h1 className="text-center mb-10">Waiting...</h1>
        <div className="flex justify-center items-center">
          <div className='mx-4 md:mx-6 text-center'>
            <p className='mb-4'>{days}</p>
            <span>days</span>
          </div>
          <div className='mx-4 md:mx-6 text-center'>
            <p className='mb-4'>{hours}</p>
            <span>hours</span>
          </div>
          <div className='mx-4 md:mx-6 text-center'>
            <p className='mb-4'>{mins}</p>
            <span>mins</span>
          </div>
          <div className='mx-4 md:mx-6 text-center'>
            <p className='mb-4'>{seconds}</p>
            <span>seconds</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountDown;
