import { useState } from "react";
import { motion } from "framer-motion";
import andesha from '../../assets/andesha.jpg'
import "../Gift/gift.css";

const contentVariants = {
  hidden: {
    opacity: 0,
    y: "-40vh",
  },
  visible: (custom) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: custom * 0.2,
      duration: custom * 0.5,
    },
  }),
};
const Gift = () => {
  const [isOpen, setIsOpen] = useState(false);
  function createHeart() {
    const parent = document.querySelector('.body');
    const heart = document.createElement("div");
    heart.classList.add("heart");
    heart.style.left = Math.random() * 100 + "vw";
    heart.style.animationDuration = Math.random() * 2 + 3 + "s";
    heart.innerText = "💜";

    parent.appendChild(heart);

    setTimeout(() => {
      heart.remove();
    }, 5000);
  }
  if (isOpen) {
    setInterval(() => {
      createHeart();
    }, 500);
  }
  return (
    <>
      {isOpen ? (
        <>
          <div className="body">
            <div className="present">
              <motion.div
                initial={{ y: 0, opacity: 1 }}
                animate={{ y: "-100vh", opacity: 0 }}
                transition={{ duration: 1 }}
                className="lid"
              >
                <span></span>
              </motion.div>

              <div className="promo">
                <motion.img
                  className="andesha-img"
                  src={andesha}
                  alt="Andesha_photo"
                  variants={contentVariants}
                  initial="hidden"
                  animate="visible"
                  custom={1}
                />
                <motion.p
                  variants={contentVariants}
                  initial="hidden"
                  animate="visible"
                  custom={2}
                >
                  Happy Birthday To You
                </motion.p>
                <motion.h2
                  variants={contentVariants}
                  initial="hidden"
                  animate="visible"
                  custom={3}
                >
                  Andesha
                </motion.h2>
                <motion.p
                  variants={contentVariants}
                  initial="hidden"
                  animate="visible"
                  custom={4}
                >
                  Glad To Have You In My LIFE
                </motion.p>
                <motion.p
                  variants={contentVariants}
                  initial="hidden"
                  animate="visible"
                  custom={5}
                >
                  😁💜
                </motion.p>
              </div>
              <motion.div
                initial={{ y: 0, opacity: 1 }}
                animate={{ y: "100vh", opacity: 0 }}
                transition={{ duration: 1 }}
                className="box"
              >
                <span></span>
                <span></span>
              </motion.div>
            </div>
          </div>
        </>
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          className="body"
        >
          <div className="main">
            <div className="present">
              <div className="lid">
                <span></span>
              </div>
              <div className="box">
                <span></span>
                <span></span>
              </div>
            </div>
            <button className="btn" onClick={() => setIsOpen(true)}>
              <p>Click Me To Open</p>
            </button>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default Gift;
